<template>
  <b-card :title="isEditMode ? 'Enter Main Points' : 'Main Points'"
          class="mb-3"
  >
    <b-form-group label="Main Points"
                  label-for="point-inputs"
    >
      <b-input-group class="mb-2">
        <b-form-input
          v-model="mainPoints.point_1"
          placeholder="Main Point 1"
        />
      </b-input-group>
      <b-input-group class="mb-2">
        <b-form-input
          v-model="mainPoints.point_2"
          placeholder="Main Point 2"
        />
      </b-input-group>
      <b-input-group class="mb-2">
        <b-form-input
          v-model="mainPoints.point_3"
          placeholder="Main Point 3"
        />
      </b-input-group>
    </b-form-group>
  </b-card>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
  },
  props: {
    mainPoints: {
      type: Object,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
