<template>
  <b-modal
    :visible="true"
    size="xl"
    :title="lessonInfo.lesson_name"
    no-close-on-backdrop
    @hide="$emit('close')"
    @close="$emit('close')"
  >
    <validation-observer ref="generateLessonForm">
      <b-row>
        <!-- Domain -->
        <b-col
          md="6"
          lg="6"
        >
          <validation-provider
            v-slot="{ errors }"
            name="domain"
            rules="required"
          >
            <b-form-group
              :label="$t('labels.create-problem.domain')"
              label-for="Domain"
            >
              <b-form-select
                v-model="state.selectedDomain"
                :options="options.domains"
                placeholder="Select Domain"
                @change="getStandardsByDomain"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          md="6"
          lg="6"
        >
          <validation-provider
            v-slot="{ errors }"
            name="standard"
            rules="required"
          >
            <b-form-group
              :label="$t('labels.create-problem.standard')"
              label-for="Standard"
            >
              <b-form-select
                v-model="state.selectedStandard"
                :options="options.standard"
                placeholder="Select Standard"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
    <main-points :key="state.selectedStandard && selectedMainPoint"
                 :main-points="selectedMainPoint"
                 :is-edit-mode="false"
    />
    <div v-if="jsonResponse">
      <JsonEditorVue
        v-model="jsonResponse"
        :debounce="1000"
        :stringified="false"
        mode="tree"
      />
      <b-button class="mt-2"
                variant="outline-secondary"
                :disabled="state.isLessonGenerating"
                @click="regenerateLessonFromDataBlock"
      >
        <b-spinner v-show="state.isLessonGenerating"
                   small
        />  Confirm JSON and Generate Lesson
      </b-button>
    </div>
    <template #modal-footer>
      <b-button variant="secondary"
                @click="$emit('close')"
      >
        Close
      </b-button>
      <b-button variant="primary"
                :disabled="state.isProcessing"
                @click="confirmAction"
      >
        <b-spinner v-show="state.isProcessing"
                   small
        /> Generate Json
      </b-button>
    </template>
  </b-modal>
</template>

<script setup>
import {
  BModal, BButton, BFormGroup, BFormSelect, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import {
  onMounted, reactive, getCurrentInstance, ref, computed,
} from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import MainPoints from '@/views/super/standard/components/standard/MainPoints.vue'
import { getFactorialLessonData } from '@/auth/jwt/useDatablock'
import JsonEditorVue from 'json-editor-vue'

const generateLessonForm = ref(null)
const jsonResponse = ref(localStorage.getItem('_checkData') ? JSON.parse(localStorage.getItem('_checkData')) : null)

const props = defineProps({
  lessonId: {
    type: Number,
    required: true,
  },
})

const lessonInfo = ref(null)

const options = reactive({
  domains: [],
  standard: [],
})

const state = reactive({
  selectedDomain: null,
  selectedStandard: null,
  isProcessing: false,
  isLessonGenerating: false,
})

onMounted(() => {
  useJwt.getLessonDetailInfo(props.lessonId).then(res => {
    console.log(res)
    lessonInfo.value = res.data
    options.domains = res.data.domain.map(domain => ({
      text: domain.name,
      value: domain.id,
    }))
  }).catch(() => {
  }).finally(() => {
  })
})

const getStandardsByDomain = value => {
  useJwt.getStandardsByDomain(value).then(response => {
    options.standard = response.data.data.map(item => ({
      text: item.name,
      value: item.id,
      main_points: item.main_points,
    }))
  })
}

const selectedMainPoint = computed(() => options.standard.find(i => i.value === state.selectedStandard)?.main_points || {})

const root = getCurrentInstance().proxy.$root

const confirmAction = () => {
  // eslint-disable-next-line consistent-return
  generateLessonForm.value.validate().then(success => {
    if (!success) {
      return null
    }
    state.isProcessing = true
    if (!selectedMainPoint.value.point_1 || !selectedMainPoint.value.point_2 || !selectedMainPoint.value.point_3) {
      console.log(selectedMainPoint.value)
      root.showError('Please Enter all main points')
    }
    const standard = options.standard.find(i => i.value === state.selectedStandard)
    const domain = options.domains.find(i => i.value === state.selectedDomain)
    console.log(standard, domain)
    getFactorialLessonData({
      gr: lessonInfo.value.grade_name,
      dm: domain.text,
      std: standard.text,
      mp1: selectedMainPoint.value.point_1,
      mp2: selectedMainPoint.value.point_2,
      mp3: selectedMainPoint.value.point_3,
      message: lessonInfo.value.lesson_name,
    }).then(response => {
      const responseData = JSON.parse(response.data.message)
      jsonResponse.value = responseData
      root.showSuccess('Data Obtained from DataBlock')
    }).catch(error => {
      root.showErrorMessage(error)
    }).finally(() => {
      state.isProcessing = false
    })
  })
}

const regenerateLessonFromDataBlock = () => {
  state.isLessonGenerating = true
  useJwt.createLessonFromDataBlock(props.lessonId, {
    jsonData: jsonResponse.value,
    mainPoints: selectedMainPoint.value,
  }).then(response => {
    root.showSuccessMessage(response)
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    state.isLessonGenerating = false
  })
}

</script>
